import Mortgage from "../../Loan/Mortgage";
import OverpayablePeriod from "../../Period/OverpayablePeriod";
import BalanceBehaviourInterface from "./BalanceBehaviourInterface";
import OverpaymentManager from "../../Overpayment/OverpaymentManager";
import MortgageTypeBehaviourInterface from "./Type/MortgageTypeBehaviourInterface";

export default class OverpaymentBalanceBehaviour implements BalanceBehaviourInterface {
  mortgageTypeBehaviour: MortgageTypeBehaviourInterface;
  mortgage: Mortgage;
  overpaymentManager: OverpaymentManager;

  constructor(mortgageTypeBehaviour: MortgageTypeBehaviourInterface, mortgage: Mortgage, overpayment: OverpaymentManager) {
    this.mortgageTypeBehaviour = mortgageTypeBehaviour;
    this.mortgage = mortgage;
    this.overpaymentManager = overpayment;
  }

  decreaseBalance(per: number, period: OverpayablePeriod): void {
    const previousBalance = this.mortgage.getBalance();

    this.mortgageTypeBehaviour.setPayment(period, period.getPayment());
    this.mortgageTypeBehaviour.setPrincipal(period, period.getPayment() - period.getInterest());
    this.mortgageTypeBehaviour.decreaseBalance(period, period.getPrincipal() + this.overpaymentManager.getOverpayment(per, period));

    if (this.mortgage.getBalance() === 0) {
      // Partial overpayment, not enough in balance after payment of capital to add full overpayment
      this.mortgageTypeBehaviour.setPrincipal(period, period.getPrincipal() + period.getOverpayment());
      period.setOverpayment(previousBalance - period.getPayment());
    } else {
      // Full overpayment
      const overpayment = this.overpaymentManager.getOverpayment(per, period);
      this.mortgageTypeBehaviour.setPrincipal(period, period.getPrincipal() + overpayment);
      period.setOverpayment(overpayment);
    }
  }

  getPayment(per: number, period: OverpayablePeriod): number {
    return this.mortgage.getBalance() - this.overpaymentManager.getOverpayment(per, period) + period.getInterest();
  }

  getPrincipal(per: number, period: OverpayablePeriod): number {
    return period.getPayment() - period.getInterest() + this.overpaymentManager.getOverpayment(per, period);
  }
}