import LoanTypeInterface from "./LoanTypeInterface";

export default class Mortgage implements LoanTypeInterface {
  recurrence: number;
  apr: number;
  nper: number;
  pv: number;
  fv: number;
  type: string;
  balance: number;
  payment: number;

  constructor(recurrence?: number, nper?: number, pv?: number, fv: number = 0, type: string = 'repayment') {
    this.recurrence = recurrence;
    this.nper = nper;
    this.pv = pv;
    this.fv = fv;
    this.type = type;
    this.balance = this.pv;
    this.payment = 0;
    this.apr = 0;
  }

  getMonthlyPayment(): number {
    return this.payment;
  };

  setMonthlyPayment(payment: number): Mortgage {
    this.payment = payment; 

    return this;
  }

  setApr(apr: number): Mortgage {
    this.apr = apr;

    return this;
  }

  getApr(): number {
    return this.apr;
  }

  getPeriodicInterestRate(): number {
    return this.apr / this.recurrence / 100;
  }

  getRecurrence(): number {
    return this.recurrence;
  }

  setRecurrence(recurrence: number): Mortgage {
    this.recurrence = recurrence;

    return this;
  }

  getPeriods(): number {
    return this.nper;
  }

  setPeriods(periods: number): Mortgage {
    this.nper = periods;

    return this;
  }

  getDebt(): number {
    return this.pv;
  }

  setDebt(debt: number): Mortgage {
    this.pv = debt;
    this.balance = this.pv;

    return this;
  }

  getBalance(): number {
    return this.balance;
  }

  setBalance(balance: number): Mortgage {
    this.balance = balance;

    return this;
  }

  getType(): string {
    return this.type;
  }

  setType(type: string): Mortgage {
    this.type = type;

    return this;
  }

  decreaseBalance(amount: number): Mortgage {
    this.balance -= amount;

    return this;
  }
}