import Mortgage from "../Loan/Mortgage";
import OverpayablePeriod from "../Period/OverpayablePeriod";
import UnitInterface from "../Unit/UnitInterface";
import AbstractOverpayment from "./AbstractOverpayment";

export default class RecurringOverpayment extends AbstractOverpayment {
  private frequency: number;

  constructor(amount: UnitInterface, frequency: number, mortgage: Mortgage) {
    super();
    this.amount = amount;
    this.frequency = frequency;
    this.mortgage = mortgage;
  }

  getOverpayment(per: number, period: OverpayablePeriod): number {
    if ((per * this.frequency) % this.mortgage.getRecurrence() === 0 && !period.getIsLastPeriod()) {
      return this.amount.toValue();
    }

    return 0;
  }
}