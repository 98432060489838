import Finance from "../../Finance/Finance";
import Mortgage from "../../Loan/Mortgage";
import InputBehaviourInterface from "./InputBehaviourInterface";

export default class MonthlyPaymentInputBehaviour implements InputBehaviourInterface {
  mortgage: Mortgage;
  finance: Finance;

  constructor(mortgage: Mortgage, finance: Finance) {
    this.mortgage = mortgage;
    this.finance = finance;
  }

  getMonthlyPayment(): number {
    return this.mortgage.getMonthlyPayment();
  }

  setMonthlyPayment(payment: number): InputBehaviourInterface {
    this.mortgage.setMonthlyPayment(payment);

    this.setApr(this.getApr());

    return this;
  }

  getApr(): number {
    const nper = this.mortgage.getPeriods();
    const pmt = this.mortgage.getMonthlyPayment();
    const pv = this.mortgage.getDebt();

    return this.finance.RATE(nper, -pmt, pv, 0, 0) * this.mortgage.getRecurrence() * 100;
  }

  setApr(rate: number): InputBehaviourInterface {
    this.mortgage.setApr(rate);

    return this;
  }

  getRate(): number {
    return this.mortgage.getPeriodicInterestRate();
  }
}