import Mortgage from "../../../Loan/Mortgage";
import PeriodInterface from "../../../Period/PeriodInterface";
import MortgageTypeBehaviourInterface from "./MortgageTypeBehaviourInterface";

export default class RepaymentMortgageTypeBehaviour implements MortgageTypeBehaviourInterface {
  mortgage: Mortgage;

  constructor(mortgage: Mortgage) {
    this.mortgage = mortgage;
  }

  getPayment(monthlyPayment: number): number {
    return monthlyPayment;
  }

  setPayment(period: PeriodInterface, pmt: number): MortgageTypeBehaviourInterface {
    period.setPayment(pmt);

    return this;
  }

  getPrincipal(pmt: number, interest: number): number {
    return pmt - interest;
  }

  setPrincipal(period: PeriodInterface, pcp: number): MortgageTypeBehaviourInterface {
    period.setPrincipal(pcp);

    return this;
  }

  decreaseBalance(period: PeriodInterface, principal: number): void {
    this.mortgage.decreaseBalance(Math.min(principal, this.mortgage.getBalance()));
    period.setBalance(this.mortgage.getBalance());
  }
}
