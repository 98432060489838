import PayableInterface from "../Payable/PayableInterface";

export class Scheduler {
  payable: PayableInterface;

  constructor(payable: PayableInterface) {
    this.payable = payable;
  }

  calculateSchedule(): Array<Object|null> {
    const schedule = [];

    if (this.payable) {
      for (let per = 1; per <= this.payable.getPeriods(); per++) {
        schedule.push(this.payable.getPayment(per).getResult());
      }
    }

    return schedule;
  }
}