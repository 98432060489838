import Alpine from 'alpinejs/dist/module.esm';
import OverpayableBuilder from './Builder/OverpayableBuilder';
import SimplePayableBuilder from './Builder/SimplePayableBuilder';
import Finance from './Finance/Finance';
import OverpaymentManager from './Overpayment/OverpaymentManager';
import { Scheduler } from './Scheduler/Scheduler';

declare global {
  interface Window {
    Alpine:any;
    OverpayableBuilder:any;
    SimplePayableBuilder:any;
    Finance:any;
    OverpaymentManager:any;
    Scheduler:any;
  }
}

window.addEventListener('DOMContentLoaded', event => {
  window.Alpine = Alpine;
  window.OverpayableBuilder = OverpayableBuilder;
  window.SimplePayableBuilder = SimplePayableBuilder;
  window.Finance = Finance;
  window.OverpaymentManager = OverpaymentManager;
  window.Scheduler = Scheduler;
});