import Mortgage from "../../../Loan/Mortgage";
import PeriodInterface from "../../../Period/PeriodInterface";
import MortgageTypeBehaviourInterface from "./MortgageTypeBehaviourInterface";

export default class InterestOnlyMortgageTypeBehaviour implements MortgageTypeBehaviourInterface {
  mortgage: Mortgage;

  constructor(mortgage: Mortgage) {
    this.mortgage = mortgage;
  }

  getPayment(): number {
    return 0;
  }

  setPayment(period: PeriodInterface): MortgageTypeBehaviourInterface {
    period.setPayment(0);

    return this;
  }
  
  getPrincipal(): number {
    return 0;
  }

  setPrincipal(period: PeriodInterface): MortgageTypeBehaviourInterface {
    period.setPrincipal(0);

    return this;
  }

  decreaseBalance(): void {
    
  }
}