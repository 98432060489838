import Finance from "../../Finance/Finance";
import Mortgage from "../../Loan/Mortgage";
import InputBehaviourInterface from "./InputBehaviourInterface";

export default class AprInputBehaviour implements InputBehaviourInterface {
  mortgage: Mortgage;
  finance: Finance;

  constructor(mortgage: Mortgage, finance: Finance) {
    this.mortgage = mortgage;
    this.finance = finance;
  }

  getMonthlyPayment(): number {
    const rate = this.mortgage.getPeriodicInterestRate();
    const nper = this.mortgage.getPeriods();
    const pv = this.mortgage.getDebt();

    return -this.finance.PMT(rate, nper, pv, 0, 0);
  }

  setMonthlyPayment(payment: number): InputBehaviourInterface {
    this.mortgage.setMonthlyPayment(payment);

    return this;
  }

  getApr(): number {
    return this.mortgage.getApr();
  }

  setApr(apr: number): InputBehaviourInterface {
    this.mortgage.setApr(apr);

    return this;
  }

  getRate(): number {
    return this.mortgage.getPeriodicInterestRate();
  }
}