import OverpayablePeriod from "../Period/OverpayablePeriod";
import AbstractOverpayment from "./AbstractOverpayment";

export default class OverpaymentManager {
  private overpaymentTypes: Array<AbstractOverpayment>;

  constructor() {
    this.overpaymentTypes = new Array();
  }

  addOverpayementType(overpaymentType: AbstractOverpayment): OverpaymentManager {
    this.overpaymentTypes.push(overpaymentType);

    return this;
  }

  hasOverpaymentTypes(): boolean {
    return this.overpaymentTypes.length !== 0;
  }

  getOverpayment(per: number, period: OverpayablePeriod): number {
    return this.overpaymentTypes.reduce((sum: number, overpaymentType: AbstractOverpayment) => {
      return sum + overpaymentType.getOverpayment(per, period);
    }, 0);
  }
}