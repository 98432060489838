import Payable from "./PayableInterface";
import InputBehaviourInterface from "./Input/InputBehaviourInterface";
import BalanceBehaviourInterface from "./Balance/BalanceBehaviourInterface";
import PeriodInterface from "../Period/PeriodInterface";
import Mortgage from "../Loan/Mortgage";
import SimplePeriod from "../Period/SimplePeriod";

export default class SimplePayable implements Payable {
  inputBehaviour: InputBehaviourInterface;
  balanceBehaviour: BalanceBehaviourInterface;
  mortgage: Mortgage;

  constructor(inputBehaviour: InputBehaviourInterface, balanceBehaviour: BalanceBehaviourInterface, mortgage: Mortgage) {
    this.inputBehaviour = inputBehaviour;
    this.balanceBehaviour = balanceBehaviour;
    this.mortgage = mortgage;
  }

  getPayment(per: number): PeriodInterface {
    let pmt = this.inputBehaviour.getMonthlyPayment();
    const rate = this.inputBehaviour.getRate();

    const interest = rate * this.mortgage.getBalance();

    const mortgageStart = new Date();
    const date = new Date(
      mortgageStart.getFullYear(),
      mortgageStart.getMonth() + per,
      mortgageStart.getDate()
    );

    const period = new SimplePeriod(per, date, pmt, 0, interest, this.mortgage.getBalance());

    if (this.mortgage.getBalance() >= pmt) {
      this.balanceBehaviour.decreaseBalance(per, period);
    } else {
      period
        .setPayment(this.balanceBehaviour.getPayment(per, period))
        .setPrincipal(this.balanceBehaviour.getPrincipal(per, period))
        .setBalance(0)
      ;

      this.mortgage.setBalance(0);
      this.mortgage.setPeriods(per);
    }

    return period;
  }

  getPeriods(): number {
    return this.mortgage.getPeriods();
  }
}