export default class Finance {
  PMT(rate: number, nper: number, pv: number, fv: number, type: number): number {
		if (!fv) fv = 0;
		if (!type) type = 0;

		if (rate == 0) return -(pv + fv)/nper;
		
		const pvif = Math.pow(1 + rate, nper);
		let pmt = rate / (pvif - 1) * -(pv * pvif + fv);

		if (type == 1) {
			pmt /= (1 + rate);
		};

		return pmt;
	}

  FV(rate: number, nper: number, pmt: number, pv: number, type: number): number {
    const pow = Math.pow(1 + rate, nper);
    let fv;
  
    pv = pv || 0;
    type = type || 0;
  
    if (rate) {
      fv = (pmt*(1+rate*type)*(1-pow)/rate)-pv*pow;
    } else {
      fv = -1 * (pv + pmt * nper);
    }

    return fv;
  }

  IPMT(rate: number, per: number, nper: number, pv: number, fv: number, type: number): number {
    type = (typeof type === 'undefined') ? 0 : type;
    
    const payment = this.PMT(rate, nper, pv, fv, type);
    
    let interest;
    if (type === 1) {
      interest = this.FV(rate, per - 2, payment, pv, 1) - payment;
    } else {
      interest = this.FV(rate, per - 1, payment, pv, 0);
    }
    
    return interest * rate;
  }

  PPMT(rate: number, per: number, nper: number, pv: number, fv: number, type: number): number | null {
		if (per < 1 || (per >= nper + 1)) return null;
		const pmt = this.PMT(rate, nper, pv, fv, type);
		const ipmt = this.IPMT(rate, per, nper, pv, fv, type);
		return pmt - ipmt;
	}

  RATE(nper: number, pmt: number, pv: number, fv: number = 0, type: number = 0, guess: number = 0.01) {
    // Sets the limits for possible guesses to any
    // number between 0% and 100%
    let lowLimit = 0;
    let highLimit = 1;

    // Defines a tolerance of up to +/- 0.00005% of pmt, to accept
    // the solution as valid.
    const tolerance = Math.abs(0.00000005 * pmt);

    // Tries at most 40 times to find a solution within the tolerance.
    for (let i = 0; i < nper; i++) {
        // Resets the balance to the original pv.
        let balance = pv;

        // Calculates the balance at the end of the loan, based
        // on loan conditions.
        for (let j = 0; j < nper; j++ ) {
            if (type == 0) {
                // Interests applied before payment
                balance = balance * (1 + guess) + pmt;
            } else {
                // Payments applied before insterests
                balance = (balance + pmt) * (1 + guess);
            }
        }

        // Returns the guess if balance is within tolerance.  If not, adjusts
        // the limits and starts with a new guess.
        if (Math.abs(balance + fv) < tolerance) {
            return guess;
        } else if (balance + fv > 0) {
            // Sets a new highLimit knowing that
            // the current guess was too big.
            highLimit = guess;
        } else  {
            // Sets a new lowLimit knowing that
            // the current guess was too small.
            lowLimit = guess;
        }

        // Calculates the new guess.
        guess = (highLimit + lowLimit) / 2;
    }

    // Returns null if no acceptable result was found after 40 tries.
    return null;
  };
}