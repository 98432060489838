import Mortgage from "../Loan/Mortgage";
import OverpayablePeriod from "../Period/OverpayablePeriod";
import UnitInterface from "../Unit/UnitInterface";

export default abstract class AbstractOverpayment {
  protected amount: UnitInterface;
  protected payment: OverpayablePeriod;
  protected mortgage: Mortgage;

  abstract getOverpayment(per: number, period: OverpayablePeriod): number
}