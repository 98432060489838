import PeriodInterface from "./PeriodInterface";

export default class OverpayablePeriod implements PeriodInterface {
  paymentNumber: number;
  date: Date;
  payment: number;
  principal: number;
  interest: number;
  balance: number;
  isLastPeriod: boolean;
  overpayment: number;

  constructor(paymentNumber: number, date: Date, payment: number, principal: number, interest: number, balance: number, overpayment: number = 0) {
    this.paymentNumber = paymentNumber;
    this.date = date;
    this.payment = payment;
    this.principal = principal;
    this.interest = interest;
    this.balance = balance;
    this.overpayment = overpayment;
    this.isLastPeriod = this.balance <= this.payment;
  }

  getPaymentNumber(): number {
    return this.paymentNumber;
  }

  setPaymentNumber(paymentNumber: number): PeriodInterface {
    this.paymentNumber = paymentNumber;

    return this;
  }

  getPaymentDate(): string {
    return this.date.getDate() + '/' + (this.date.getMonth() + 1) + '/' + this.date.getFullYear();
  }

  setPaymentDate(date: Date): PeriodInterface {
    this.date = date;

    return this;
  }

  getPayment(): number {
    return this.payment;
  }

  setPayment(payment: number): this {
    this.payment = payment;

    return this;
  }

  getPrincipal(): number {
    return this.principal;
  }

  setPrincipal(principal: number): this {
    this.principal = principal;

    return this;
  }

  getInterest(): number {
    return this.interest;
  }

  getBalance(): number {
    return this.balance;
  }

  setBalance(balance: number): this {
    this.balance = balance;

    return this;
  }

  getIsLastPeriod(): boolean {
    return this.isLastPeriod;
  }

  getOverpayment(): number {
    return this.overpayment;
  }

  setOverpayment(overpayment: number): this {
    this.overpayment = overpayment;

    return this;
  }

  getResult(): object {
    return {
      paymentNumber: this.getPaymentNumber(),
      paymentDate: this.getPaymentDate(),
      payment: this.getPayment(),
      principal: this.getPrincipal(),
      interest: this.getInterest(),
      balance: this.getBalance(),
      overpayment: this.getOverpayment(),
    };
  }
}