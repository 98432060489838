import UnitInterface from "./UnitInterface";

export default class PercentageUnit implements UnitInterface {
  private percentage: number;
  private total: number;

  constructor(percentage: number, total: number) {
    this.percentage = percentage;
    this.total = total;
  }

  toValue(): number {
    return (this.percentage / 100) * this.total;
  }
}