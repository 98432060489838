import Mortgage from "../../Loan/Mortgage";
import PeriodInterface from "../../Period/PeriodInterface";
import MortgageTypeBehaviourInterface from "./Type/MortgageTypeBehaviourInterface";
import BalanceBehaviourInterface from "./BalanceBehaviourInterface";
import SimplePeriod from "../../Period/SimplePeriod";

export default class SimpleBalanceBehaviour implements BalanceBehaviourInterface {
  mortgageTypeBehaviour: MortgageTypeBehaviourInterface;
  mortgage: Mortgage;

  constructor(mortgageTypeBehaviour: MortgageTypeBehaviourInterface, mortgage: Mortgage) {
    this.mortgageTypeBehaviour = mortgageTypeBehaviour;
    this.mortgage = mortgage;
  }

  decreaseBalance(per: number, period: SimplePeriod): void {
    this.mortgageTypeBehaviour.setPayment(period, period.getPayment());
    this.mortgageTypeBehaviour.setPrincipal(period, period.getPayment() - period.getInterest());
    this.mortgageTypeBehaviour.decreaseBalance(period, period.getPrincipal());
  }

  getPayment(): number {
    return this.mortgage.getBalance();
  }

  getPrincipal(per: number, payment: PeriodInterface): number {
    return payment.getPayment() - payment.getInterest();
  }
}