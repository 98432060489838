import Finance from "../Finance/Finance";
import Mortgage from "../Loan/Mortgage";
import BalanceBehaviourInterface from "../Payable/Balance/BalanceBehaviourInterface";
import SimpleBalanceBehaviour from "../Payable/Balance/SimpleBalanceBehaviour";
import InterestOnlyMortgageTypeBehaviour from "../Payable/Balance/Type/InterestOnlyMortgageTypeBehaviour";
import MortgageTypeBehaviourInterface from "../Payable/Balance/Type/MortgageTypeBehaviourInterface";
import RepaymentMortgageTypeBehaviour from "../Payable/Balance/Type/RepaymentMortgageTypeBehaviour";
import AprInputBehaviour from "../Payable/Input/AprInputBehaviour";
import InputBehaviourInterface from "../Payable/Input/InputBehaviourInterface";
import MonthlyPaymentInputBehaviour from "../Payable/Input/MonthlyPaymentInputBehaviour";
import PayableInterface from "../Payable/PayableInterface";
import SimplePayable from "../Payable/SimplePayable";
import BuilderInterface from "./BuilderInterface";

export default class SimplePayableBuilder implements BuilderInterface {
  protected finance: Finance;
  protected inputBehaviour: InputBehaviourInterface;
  protected mortgageTypeBehaviour: MortgageTypeBehaviourInterface;
  protected balanceBehaviour: BalanceBehaviourInterface;
  protected mortgage: Mortgage;

  constructor(
    finance: Finance,
    inputBehaviour?: InputBehaviourInterface, 
    mortgageTypeBehaviour?: MortgageTypeBehaviourInterface,
    balanceBehaviour?: BalanceBehaviourInterface,
    mortgage?: Mortgage,
  ) {
    this.finance = finance;
    this.inputBehaviour = inputBehaviour;
    this.mortgageTypeBehaviour = mortgageTypeBehaviour;
    this.balanceBehaviour = balanceBehaviour;
    this.mortgage = mortgage ?? new Mortgage();
  }

  setDebt(debt: number): BuilderInterface {
    this.mortgage.setDebt(debt);

    return this;
  }

  setTerm(term: number): BuilderInterface {
    this.mortgage.setPeriods(term);

    return this;
  }

  setFrequency(frequency: number): BuilderInterface {
    this.mortgage.setRecurrence(frequency);

    return this;
  }

  setType(type: string): BuilderInterface {
    this.mortgage.setType(type);

    if (type === 'repayment') {
      this.mortgageTypeBehaviour = new RepaymentMortgageTypeBehaviour(this.mortgage);
    } else {
      this.mortgageTypeBehaviour = new InterestOnlyMortgageTypeBehaviour(this.mortgage);
    }

    return this;
  }

  setApr(apr: number): BuilderInterface {
    if (apr > 0) {
      this.inputBehaviour = new AprInputBehaviour(this.mortgage, this.finance);
      this.inputBehaviour.setApr(apr);
    }

    return this;
  }

  setMonthlyPayment(monthlyPayment: number): BuilderInterface {
      if (monthlyPayment > 0) {
        this.inputBehaviour = new MonthlyPaymentInputBehaviour(this.mortgage, this.finance);
        this.inputBehaviour.setMonthlyPayment(monthlyPayment);
      }

      return this;
  }

  build(): PayableInterface|null {
    if (!this.inputBehaviour || !this.mortgageTypeBehaviour) {
      return null;
    }

    this.balanceBehaviour = new SimpleBalanceBehaviour(this.mortgageTypeBehaviour, this.mortgage);
    return new SimplePayable(this.inputBehaviour, this.balanceBehaviour, this.mortgage);
  }
}