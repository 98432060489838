import AprInputBehaviour from "../Payable/Input/AprInputBehaviour";
import Finance from "../Finance/Finance";
import Mortgage from "../Loan/Mortgage";
import InputBehaviourInterface from "../Payable/Input/InputBehaviourInterface";
import MonthlyPaymentInputBehaviour from "../Payable/Input/MonthlyPaymentInputBehaviour";
import MortgageTypeBehaviourInterface from "../Payable/Balance/Type/MortgageTypeBehaviourInterface";
import RepaymentMortgageTypeBehaviour from "../Payable/Balance/Type/RepaymentMortgageTypeBehaviour";
import InterestOnlyMortgageTypeBehaviour from "../Payable/Balance/Type/InterestOnlyMortgageTypeBehaviour";
import BuilderInterface from "./BuilderInterface";
import Overpayable from "../Payable/Overpayable";
import BalanceBehaviourInterface from "../Payable/Balance/BalanceBehaviourInterface";
import OverpaymentBalanceBehaviour from "../Payable/Balance/OverpaymentBalanceBehaviour";
import OneOffOverpayment from "../Overpayment/OneOffOverpayment";
import RecurringOverpayment from "../Overpayment/RecurringOverpayment";
import OverpaymentManager from "../Overpayment/OverpaymentManager";
import PayableInterface from "../Payable/PayableInterface";
import OverpayableBuilderInterface from "./OverpayableBuilderInterface";
import PercentageUnit from "../Unit/PercentageUnit";
import NumberUnit from "../Unit/NumberUnit";

export default class OverpayableBuilder implements BuilderInterface, OverpayableBuilderInterface {
  protected finance: Finance;
  protected overpaymentManager: OverpaymentManager;
  protected inputBehaviour: InputBehaviourInterface;
  protected mortgageTypeBehaviour: MortgageTypeBehaviourInterface;
  protected balanceBehaviour: BalanceBehaviourInterface;
  protected mortgage: Mortgage;

  constructor(
    finance: Finance,
    overpaymentManager: OverpaymentManager,
    inputBehaviour?: InputBehaviourInterface, 
    mortgageTypeBehaviour?: MortgageTypeBehaviourInterface,
    balanceBehaviour?: BalanceBehaviourInterface,
    mortgage?: Mortgage,
  ) {
    this.finance = finance;
    this.overpaymentManager = overpaymentManager;
    this.inputBehaviour = inputBehaviour;
    this.mortgageTypeBehaviour = mortgageTypeBehaviour;
    this.balanceBehaviour = balanceBehaviour;
    this.mortgage = mortgage ?? new Mortgage();
  }

  setDebt(debt: number): OverpayableBuilder {
    this.mortgage.setDebt(debt);

    return this;
  }

  setTerm(term: number): OverpayableBuilder {
      this.mortgage.setPeriods(term);

      return this;
  }

  setFrequency(frequency: number): OverpayableBuilder {
    this.mortgage.setRecurrence(frequency);

    return this;
  }

  setType(type: string): OverpayableBuilder {
    this.mortgage.setType(type);

    if (type === 'repayment') {
      this.mortgageTypeBehaviour = new RepaymentMortgageTypeBehaviour(this.mortgage);
    } else {
      this.mortgageTypeBehaviour = new InterestOnlyMortgageTypeBehaviour(this.mortgage);
    }

    return this;
  }

  setApr(apr: number): OverpayableBuilder {
    if (apr > 0) {
      this.inputBehaviour = new AprInputBehaviour(this.mortgage, this.finance);
      this.inputBehaviour.setApr(apr);
    }

    return this;
  }

  setMonthlyPayment(monthlyPayment: number): OverpayableBuilder {
      if (monthlyPayment > 0) {
        this.inputBehaviour = new MonthlyPaymentInputBehaviour(this.mortgage, this.finance);
        this.inputBehaviour.setMonthlyPayment(monthlyPayment);
      }

      return this;
  }

  addOneOffOverpayment(amount: number, value: string, per: number): OverpayableBuilder {
    if (amount > 0) {
      const overpaymentValue = value === 'percent' ? new PercentageUnit(amount, this.mortgage.getDebt()) : new NumberUnit(amount);
      const overpaymentType = new OneOffOverpayment(overpaymentValue, per, this.mortgage);

      this.overpaymentManager.addOverpayementType(overpaymentType);
    }
    
    return this;
  }

  addRecurringOverpayment(amount: number, value: string, frequency: number): OverpayableBuilder {
    if (amount > 0) {
      const overpaymentValue = value === 'percent' ? new PercentageUnit(amount, this.mortgage.getDebt()) : new NumberUnit(amount);
      const overpaymentType = new RecurringOverpayment(overpaymentValue, frequency, this.mortgage);

      this.overpaymentManager.addOverpayementType(overpaymentType);
    }

    return this;
  }

  build(): PayableInterface|null {
    if (!this.inputBehaviour || !this.mortgageTypeBehaviour) {
      return null;
    }

    this.balanceBehaviour = new OverpaymentBalanceBehaviour(this.mortgageTypeBehaviour, this.mortgage, this.overpaymentManager);
    return new Overpayable(this.inputBehaviour, this.balanceBehaviour, this.mortgage)
  }
}