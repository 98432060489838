import PayableInterface from "./PayableInterface";
import InputBehaviourInterface from "./Input/InputBehaviourInterface";
import OverpayablePeriod from "../Period/OverpayablePeriod";
import Mortgage from "../Loan/Mortgage";
import BalanceBehaviourInterface from "./Balance/BalanceBehaviourInterface";

export default class Overpayable implements PayableInterface {
  inputBehaviour: InputBehaviourInterface;
  balanceBehaviour: BalanceBehaviourInterface;
  mortgage: Mortgage;

  constructor(inputBehaviour: InputBehaviourInterface, balanceBehaviour: BalanceBehaviourInterface, mortgage: Mortgage) {
    this.inputBehaviour = inputBehaviour;
    this.balanceBehaviour = balanceBehaviour;
    this.mortgage = mortgage;
  }

  getPayment(per: number): OverpayablePeriod {
    const pmt = this.inputBehaviour.getMonthlyPayment();
    const rate = this.inputBehaviour.getRate();

    const interest = rate * this.mortgage.getBalance();

    const mortgageStart = new Date();
    const date = new Date(
      mortgageStart.getFullYear(),
      mortgageStart.getMonth() + per,
      mortgageStart.getDate()
    );

    const period = new OverpayablePeriod(per, date, pmt, 0, interest, this.mortgage.getBalance());

    if (this.mortgage.getBalance() > pmt) {
      this.balanceBehaviour.decreaseBalance(per, period);
    } else {
      period
        .setPayment(this.balanceBehaviour.getPayment(per, period))
        .setPrincipal(this.balanceBehaviour.getPrincipal(per, period))
        .setBalance(0)
      ;

      this.mortgage.setBalance(0);
      this.mortgage.setPeriods(per);
    }

    return period;
  }

  getPeriods(): number {
    return this.mortgage.getPeriods();
  }
}